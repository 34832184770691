import {Injectable} from '@angular/core';
import mixpanel, {Dict} from 'mixpanel-browser';
import {environment} from 'src/environments/environment';
import {Store} from '@ngxs/store';
import {SessionState} from 'src/app/shared/state/session/session.state';
import {Subscription} from 'rxjs';
import {Event as RoutingEvent, NavigationEnd, Router} from '@angular/router';
import {PageViewMap} from './page-view-map';
import {IAccount} from '../models/account';

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {

  /**
   * This variable shouldn't be static, but since the introduction of checking account params to determine if tracking should happen
   * it was hard to get around.  As long as this service remains a singleton used by the entire app it shouldn't matter.  If you are
   * experiencing strange tracking issues in mixpanel, ensure that this service is only provided in root and not another module!
   * @private
   */
  private static acct: IAccount|null = null;
  private selectAccount: Subscription;

  constructor(private store: Store,
              private router: Router) {
  }

  private static isMixpanelActive(): boolean {
    if (!environment.mixpanelKey) {
      return false;
    }

    return true;
    // return [488367].indexOf(MixpanelService.acct?.user?.afid) >= 0;  // 488367 is Sanderson Drake
  }

  static track(eventName: string, properties?: Dict): void {
    if (!MixpanelService.isMixpanelActive()) {
      return;
    }

    mixpanel.track(eventName, properties);
  }

  initMixpanel(): void {
    if (!environment.mixpanelKey) {
      return;
    }

    // We expect just an init() call to not cause an MTU.  To be determined.
    mixpanel.init(environment.mixpanelKey, {debug: !environment.production});

    // When the customer logs in or out, we want to let heap know to identify all events under the correct user
    this.selectAccount = this.store.select(SessionState.getAccount)
      .subscribe((account: IAccount) => {
      MixpanelService.acct = account;

      // Don't identify() if we aren't supposed to track.
      if (!MixpanelService.isMixpanelActive()) {
        return;
      }

      if (account) {
        mixpanel.identify(account.user.guid);
        mixpanel.people.set({
          cid: account.user.cid,
          afid: account.user.afid,
          Email: account.user.email,
          'Account Status': account.user.status,
          'Started as MV': account.user.is_manual_verify,
          'Started as BE': account.user.is_broken_enroll,
          'Started as Thin File': account.user.is_thin_file,
        });
      } else {
        mixpanel.reset();
      }
    });
  }

  /**
   * Subscribe to navigations events through the Angular router to record page view events
   */
  watchRoutingEvents(): void {
    this.router.events.subscribe((event: RoutingEvent) => {
      if (event instanceof NavigationEnd) {
        if (!MixpanelService.isMixpanelActive()) {
          return;
        }

        const mixEventName = PageViewMap.get(event.urlAfterRedirects);

        if (mixEventName) {
          mixpanel.track('PageView ' + mixEventName);
        }
      }
    });
  }
}
