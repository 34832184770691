import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {EcceLayoutComponent} from 'src/app/shared/layouts/ecce/ecce-layout.component';
import {AuthLayoutComponent} from './layouts/auth/auth-layout/auth-layout.component';
import {FooterComponent} from './layouts/ecce/footer/footer.component';
import {DetailPageComponent} from './layouts/ecce/detail-page/detail-page.component';
import {APP_CONFIG, AppConfig} from './config/app.config';
import {CoreModule} from '../core/core.module';
import {RouterModule} from '@angular/router';
import {NavComponent} from './layouts/ecce/nav/nav.component';
import {InfoLayoutComponent} from './layouts/info/info-layout.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {SecondaryNavComponent} from './layouts/ecce/secondary-nav/secondary-nav.component';
import {TrialCountdownComponent} from './components/trial-countdown/trial-countdown.component';
import {RecordClickDirective} from './directives/record-click.directive';
import {BackButtonDirective} from 'src/app/shared/directives/back-button.directive';
import {MatSidenavModule} from '@angular/material/sidenav';
import {AlertComponent} from 'src/app/core/components/alert/alert.component';
import {ToastComponent} from 'src/app/core/components/toasts/toast/toast.component';
import {LoadindicatorComponent} from 'src/app/core/components/loadindicator/loadindicator.component';
import {YourFileExplainedComponent} from 'src/app/shared/layouts/ecce/nav/nav-items/your-file-explained/your-file-explained.component';
import {BrandImageDirective} from 'src/app/shared/directives/brand-image.directive';

@NgModule({
  declarations: [
    EcceLayoutComponent,
    InfoLayoutComponent,
    AuthLayoutComponent,
    FooterComponent,
    DetailPageComponent,
    SecondaryNavComponent,
    TrialCountdownComponent,
  ],
  imports: [
    ClipboardModule,
    CommonModule,
    CoreModule,
    RouterModule,
    RecordClickDirective,
    BackButtonDirective,
    MatSidenavModule,
    AlertComponent,
    ToastComponent,
    LoadindicatorComponent,
    YourFileExplainedComponent,
    BrandImageDirective,
    NavComponent,
  ],
  providers: [
    {
      provide: APP_CONFIG,
      useValue: AppConfig,
    },
    CurrencyPipe,
    // Needs test to be sure global formatter doesn't break existing uses of NgbDatePicker
    // {
    //   provide: NgbDateParserFormatter,
    //   useClass: NgbDateUkParserFormatter
    // }
  ],
  exports: [
    FooterComponent,
    EcceLayoutComponent,
    InfoLayoutComponent,
    AuthLayoutComponent,
    DetailPageComponent,
    TrialCountdownComponent,
    RecordClickDirective,
  ],
})
export class SharedModule {
}
