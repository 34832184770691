import {Component} from '@angular/core';
import {AsyncPipe} from '@angular/common';
import {AccountService} from 'src/app/shared/services/account.service';
import {MixpanelService} from 'src/app/shared/mixpanel/mixpanel.service';
import {Agency} from 'src/app/shared/models/agency';
import {FileSaverService} from 'ngx-filesaver';
import {CreditReportService} from 'src/app/shared/services/credit-report.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-download-report',
  standalone: true,
  templateUrl: './download-report.component.html',
  styleUrls: ['../../nav.component.scss'],
  imports: [
    AsyncPipe,
  ],
})
export class DownloadReportComponent {

  constructor(
    public accountService: AccountService,
    private fileSaverService: FileSaverService,
    private creditReportService: CreditReportService,
    private router: Router,
  ) {
  }

  downloadReport(): void {
    // If this service is supported by the browser, we can use our progress bar page.
    if (this.fileSaverService.isFileSaverSupported) {
      this.router.navigateByUrl('/account/download-report');
    } else {
      // Otherwise, just work like it always has.
      MixpanelService.track('Click Direct download report');
      const selectedAgency = this.creditReportService.getSelectedAgencySnapshot();

      // We should put a proper modal which is aware of their current status (MV, broken enroll, thin file) and links accordingly.
      if (selectedAgency === Agency.NONE) {
        alert("You don't appear to have a credit report.  Finish your registration process to continue.");
        return;
      }

      window.open(`/api/report/pdf?agency=${selectedAgency}`, '_blank');
    }
  }

}
