import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {Store} from '@ngxs/store';
import {SessionState} from 'src/app/shared/state/session/session.state';
import {Subscription} from 'rxjs';
import {IAccount} from '../models/account';
import Clarity from '@microsoft/clarity';

@Injectable({
  providedIn: 'root',
})
export class ClarityHeatmapService {

  private selectAccount: Subscription;

  constructor(private store: Store) {
  }

  private static isActive(): boolean {
    if (!environment.clarityHeatmapProject) {
      return false;
    }

    return true;
  }

  static track(eventName: string): void {
    if (!ClarityHeatmapService.isActive()) {
      return;
    }

    Clarity.event(eventName);
  }

  init(): void {
    if (!environment.clarityHeatmapProject) {
      return;
    }

    Clarity.init(environment.clarityHeatmapProject);

    // When the customer logs in or out, we want to let heap know to identify all events under the correct user
    this.selectAccount = this.store.select(SessionState.getAccount)
      .subscribe((account: IAccount) => {
        // Don't identify() if we aren't supposed to track.
        if (!ClarityHeatmapService.isActive()) {
          return;
        }

        if (account) {
          Clarity.identify(account.user.guid);
          Clarity.setTag('cid', `${account.user.cid}`);
          Clarity.setTag('afid', `${account.user.afid}`);
          Clarity.setTag('Email', account.user.email);
          Clarity.setTag('Account Status', account.user.status);
          Clarity.setTag('Started as MV', `${account.user.is_manual_verify}`);
          Clarity.setTag('Started as BE', `${account.user.is_broken_enroll}`);
          Clarity.setTag('Started as Thin File', `${account.user.is_thin_file}`);
        }
      });
  }
}
