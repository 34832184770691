import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {NgbModalModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {LoadIndicatorInterceptor} from './interceptor/loadindicator.interceptor';
import {LogoutOnUnauthInterceptor} from './interceptor/logout-on-unauth.interceptor';
import {ServerErrorInterceptor} from './interceptor/server-error.interceptor';
import {RouterModule} from '@angular/router';
import {provideNgxWebstorage, withLocalStorage, withSessionStorage} from 'ngx-webstorage';
import {AuthSanctumInterceptor} from 'src/app/core/interceptor/auth-sanctum.interceptor';
import {Reload419Interceptor} from 'src/app/core/interceptor/reload-419.interceptor';


@NgModule({
  declarations: [],
  exports: [
    FormsModule,
    NgbModule,
    NgbModalModule,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    NgbModalModule,
    RouterModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadIndicatorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthSanctumInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LogoutOnUnauthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Reload419Interceptor,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideNgxWebstorage(
      withLocalStorage(),
      withSessionStorage(),
    ),
  ],
})
export class CoreModule {
}
