import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {SessionActions} from 'src/app/shared/state/session/session.actions';
import ClearState = SessionActions.ClearState;
import {DisconnectOpenBanking} from 'src/app/modules/rent-reporting/state/rent-reporting.actions';
import {RentReportingService} from 'src/app/modules/rent-reporting/services/rent-reporting.service';
import {AccountService} from 'src/app/shared/services/account.service';
import {tap} from 'rxjs/operators';
import {genericErrorObserver} from 'src/app/shared/observables/generic-error.observer';


export interface RentStateModel {
}


export const rentStateDefaults: RentStateModel = {
};


@State<RentStateModel>({
  name: 'rent',
  defaults: rentStateDefaults
})


@Injectable()
export class RentReportingState {

  constructor(
    private rentService: RentReportingService,
    private accountService: AccountService,
  ) {}

  /*@Selector([RentReportingState])
  static getScan(state: RentStateModel) {
    return state.scan;
  }*/

  @Action(DisconnectOpenBanking)
  disconnectOpenBanking(ctx: StateContext<RentStateModel>, action: DisconnectOpenBanking) {
    return this.rentService
      .disconnectBanking()
      .pipe(
        // Rent info is stored in account object, so fetch an updated copy
        tap(() => this.accountService.fetch().subscribe(genericErrorObserver)),
      );
    // ctx.patchState({scan: action.scan});
  }

  @Action(ClearState)
  clearState(ctx: StateContext<RentStateModel>) {
    ctx.setState(rentStateDefaults);
  }
}
