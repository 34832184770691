<div class="modal-header" *ngIf="hasHeader">
  <h5 class="modal-title" id="exampleModalLabel">{{title}}</h5>
  <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="no()"></button>
</div>

<div class="modal-body">
  <p>{{ message }}</p>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary rounded-pill" (click)="yes()">{{yesLabel}}</button>
  <button type="button" class="btn btn-outline-secondary rounded-pill" (click)="no()">{{noLabel}}</button>
</div>
