@if (isBrokenEnroll$ | async) {
<span class="nav-alt">
  <a class="nav-link" (click)="openModalFlow()" id="nav-tu-explained">
    <div class="icon-container">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M96 64c0-17.7-14.3-32-32-32S32 46.3 32 64l0 256c0 17.7 14.3 32 32 32s32-14.3 32-32L96 64zM64 480a40 40 0 1 0 0-80 40 40 0 1 0 0 80z"/></svg>
    </div>
    Credit Report I.D. Verification
  </a>
  <hr />
</span>
}
