import {Component, OnInit} from '@angular/core';
import {BrandService} from './shared/services/brand.service';
import {Title} from '@angular/platform-browser';
import {AccountService} from './shared/services/account.service';
import {AuthService} from './core/services/auth.service';
import {MixpanelService} from './shared/mixpanel/mixpanel.service';
import {ExitSurveyService} from './modules/exit-survey/exit-survey.service';
import {DarkWebService} from 'src/app/modules/dark-web/services/dark-web.service';
import {genericErrorObserver} from 'src/app/shared/observables/generic-error.observer';
import {CreditReportService} from 'src/app/shared/services/credit-report.service';
import {Agency} from 'src/app/shared/models/agency';
import {AppErrorHandler} from 'src/app/shared/errors/app-error-handler.service';
import {switchMap} from 'rxjs';
import {ClarityHeatmapService} from 'src/app/shared/services/clarity-heatmap.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private brandService: BrandService,
    private titleService: Title,
    accountService: AccountService,
    private authService: AuthService,
    private mixpanelService: MixpanelService,
    private clarityHeatmapService: ClarityHeatmapService,
    exitSurveyService: ExitSurveyService,
    darkwebService: DarkWebService,
    creditReportService: CreditReportService,
    appErrorHandler: AppErrorHandler,
  ) {
    exitSurveyService.listenForIaoFinish();

    // Detect when we log in/out.  When we login, fetch core data down.
    authService.loginStateChanges().subscribe(val => {
      if (val) {
        // Pull our account data
        accountService.fetch().subscribe(genericErrorObserver);

        // The dashboard needs this data for the darkweb summary box
        darkwebService.fetchSummary().subscribe(genericErrorObserver);
      }
    });

    // This will only fire when we are logged in with an account.
    accountService.getAccount().subscribe(acct => {
      // Find out what agency, if any, the customer is registered at
      const acctAgency = acct.getDefaultAgency();
      let agency = Agency.NONE;

      if (acctAgency != null) {
        agency = acctAgency;
      }

      // Select that agency in the state
      creditReportService.selectAgency(agency);
    });

    appErrorHandler.configureScope();
    // this.watchRoutingEvents();
  }

  /**
   * Try and fetch the account object into the state.  This will either succeed and populate
   * the state with the account object, updating all query subscriptions, or it will fail
   * and do nothing.
   * @private
   */
  private loadAuthenticationState(): void {

    this.authService
      // First, we setup our session by cookie-ing Sanctum's CSRF token
      .setCsrfCookie()
      .pipe(
        // Next, ping to see if we are already logged in
        switchMap(() => this.authService.checkAuthenticationState()),
      )
      .subscribe({error: () => console.log('Not logged in on initial account load')});
  }

  ngOnInit(): void {
    this.setTitleTagByDomain();
    this.loadAuthenticationState();
    this.mixpanelService.initMixpanel();
    this.mixpanelService.watchRoutingEvents();
    this.clarityHeatmapService.init();
  }

  private setTitleTagByDomain(): void {
    // Something like ukcreditratings
    let brand = this.brandService.get();

    // Into Ukcreditratings
    brand = brand.charAt(0).toUpperCase() + brand.slice(1);

    // Change <title> tag
    this.titleService.setTitle(brand);
  }

 /* private watchRoutingEvents() {
    this.router.events.subscribe(event => {
      switch (true) {
        case event instanceof NavigationStart: {
          console.log(event);
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          console.log(event);
          break;
        }
        default: {
          break;
        }
      }
    });
  }*/
}
