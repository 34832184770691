import {Routes} from '@angular/router';
import {AuthLayoutComponent} from './shared/layouts/auth/auth-layout/auth-layout.component';
import {AuthGuard} from './core/guards/auth.guard';
import {EcceLayoutComponent} from './shared/layouts/ecce/ecce-layout.component';
import {HasCreditReportGuard} from './shared/route-guards/has-credit-report.guard';
import {ForcePasswordResetGuard} from './shared/route-guards/force-password-reset.guard';
import {InfoLayoutComponent} from './shared/layouts/info/info-layout.component';


export const routes: Routes = [
  {
    path: '',
    redirectTo: '/auth/login',
    pathMatch: 'full'
  },
  {
    path: 'info',
    component: InfoLayoutComponent,
    loadChildren: () =>
      import('./modules/info/info.module').then(m => m.InfoModule)
  },
  {
    path: 'reactivate',
    component: InfoLayoutComponent,
    loadChildren: () =>
      import('./modules/reactivate/reactivate.module').then(m => m.ReactivateModule)
  },
  {
    path: '',
    component: EcceLayoutComponent,
    canActivate: [ForcePasswordResetGuard, AuthGuard], // Should be replaced with actual auth guard
    children: [
      {
        path: 'credit-utilization',
        canActivate: [HasCreditReportGuard],
        loadChildren: () =>
          import('./modules/credit-utilization/credit-utilization.module').then(m => m.CreditUtilizationModule)
      },
      {
        path: 'dashboard',
        loadChildren: () =>
            import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'negative-marks',
        canActivate: [HasCreditReportGuard],
        loadChildren: () =>
          import('./modules/negative-marks/negative-marks.module').then(m => m.NegativeMarksModule)
      },
      {
        path: 'disputes',
        canActivate: [HasCreditReportGuard],
        loadChildren: () =>
          import('./modules/disputes/disputes.module').then(m => m.DisputesModule)
      },
      {
        path: 'financial-data',
        canActivate: [HasCreditReportGuard],
        loadChildren: () =>
          import('./modules/financial-data/financial-data.module').then(m => m.FinancialDataModule)
      },
      {
        path: 'public-private-data',
        canActivate: [HasCreditReportGuard],
        loadChildren: () =>
          import('./modules/public-private-data/public-private-data.module').then(m => m.PublicPrivateDataModule)
      },
      {
        path: 'enquiries',
        canActivate: [HasCreditReportGuard],
        loadChildren: () =>
          import('./modules/inquiries/inquiries.module').then(m => m.InquiriesModule)
      },
      {
        path: 'offers',
        loadChildren: () =>
          import('./modules/offers/offers.module').then(m => m.OffersModule)
      },
      {
        path: 'cancel',
        loadChildren: () =>
          import('./modules/cancel/cancel.module').then(m => m.CancelModule)
      },
      {
        path: 'edu',
        loadChildren: () =>
          import('./modules/wiki/wiki.module').then(m => m.WikiModule)
      },
      {
        path: 'rent-reporting',
        loadChildren: () =>
          import('src/app/modules/rent-reporting/rent-reporting-routes').then(m => m.routes)
      },
      {
        path: 'account',
        loadChildren: () =>
          import('./modules/account/account.module').then(m => m.AccountModule)
      },
      {
        path: 'alerts',
        canActivate: [HasCreditReportGuard],
        loadChildren: () =>
          import('./modules/alerts/alerts.module').then(m => m.AlertsModule)
      },
      {
        path: 'customer-service',
        loadChildren: () =>
          import('./modules/customer-service/customer-service.module').then(m => m.CustomerServiceModule)
      },
      {
        path: 'corrections',
        loadChildren: () =>
          import('./modules/corrections/corrections.module').then(m => m.CorrectionsModule)
      },
      {
        path: 'dark-web',
        loadChildren: () =>
          import('src/app/modules/dark-web/dark-web.module').then(m => m.DarkWebModule)
      },
      {
        path: 'card-services',
        loadChildren: () =>
          import('./modules/card-services/card-services.module').then(m => m.CardServicesModule)
      },
    ]
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('src/app/modules/auth/auth-routing').then(m => m.routes)
  },
  {
    path: 'dark-web-verify-search-criterion',
    component: AuthLayoutComponent,
    children: [{
      path: '',
      loadComponent: () => import('./modules/dark-web/pages/verify-search-criterion/verify-search-criterion.component').then(m => m.VerifySearchCriterionComponent),
    }],
  },
  {
    path: 'error',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./modules/error/error.module').then(m => m.ErrorModule)
  },
  {
    path: '**',
    redirectTo: 'error/page-not-found'
  }
];
