import {Component, Input} from '@angular/core';
import {CompositeRisks} from '../../models/composite-risk';
import {NgClass, NgIf} from '@angular/common';
import {NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-risk-header',
  templateUrl: './risk-header.component.html',
  styleUrls: ['./risk-header.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgbPopoverModule,
    NgClass,
  ],
})
export class RiskHeaderComponent {

  @Input() risks: CompositeRisks;
  /**
   * Hide categories that have no breaches
   */
  @Input() hideZeros = false;
  @Input() limit6 = false;

  summaryList = ['passport', 'drivers_license', 'usernames', 'passwords', 'emails', 'phone_numbers'];


  constructor() {
  }

  // I'm leaving this here in case this changes again with the redesign
  // canShow(groupCount: number): boolean {
  //   return !this.hideZeros || groupCount > 0;
  // }

  canShow(riskCategory: string): boolean {
    return (this.limit6 && this.summaryList.includes(riskCategory)) || (this.limit6 === false);
  }
}
