import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot} from '@angular/router';
import {first} from 'rxjs';
import {CreditReportService} from '../services/credit-report.service';
import {map, tap} from 'rxjs/operators';
import {ToastService} from 'src/app/core/components/toasts/toast.service';

/**
 * This should return true for active accounts, and false for manual verify & thin file accounts
 */
export const HasCreditReportGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const creditReportService = inject(CreditReportService);
  const toastService = inject(ToastService);

  return creditReportService
    .get()
    .pipe(
      first(),
      map(report => !report.isEmpty()),
      tap(hasReport => {
        if (!hasReport) {
          toastService.info("Your report is not available");
        }
      })
    );
}
