import {Component} from '@angular/core';
import {DarkWebService} from 'src/app/modules/dark-web/services/dark-web.service';
import {combineLatest, Observable} from 'rxjs';
import {DarkwebSummary} from 'src/app/modules/dark-web/models/darkweb-summary';
import {filter, map} from 'rxjs/operators';
import {AccountService} from 'src/app/shared/services/account.service';

@Component({
  selector: 'app-darkweb-summary',
  templateUrl: './darkweb-summary.component.html',
  styleUrls: ['./darkweb-summary.component.scss'],
})
export class DarkwebSummaryComponent {

  /**
   * All summary data
   */
  summary$: Observable<DarkwebSummary>;
  /**
   * Have we completed a scan (has scan date) and were there at least 1 dark web hit (risk accumulation)
   */
  hasSummaryData$: Observable<boolean>;
  /**
   * Do we expect a darkweb report to either exist or about to exist
   */
  shouldBeFetching$: Observable<boolean>;

  constructor(
    darkwebService: DarkWebService,
    accountSevice: AccountService,
  ) {

    this.summary$ = darkwebService.getSummary();

    this.hasSummaryData$ = darkwebService.getSummary().pipe(map(summary => {
      if (!summary || !summary.lastScanDate || Object.keys(summary).length === 0) {
        return false;
      }

      return Object.values(summary.risks).find(v => v > 0);
    }));


    this.shouldBeFetching$ = combineLatest([
      accountSevice.getAccount(),
      darkwebService.getSearchCriterion(),
    ]).pipe(
      // AccountService filters null for account, but getSearchCriterion doesn't.  Make sure we have data.  If not, it's treated as false.
      filter(([acct, crit]) => crit != null),
      map(([acct, crit]) => {
        // Data partners don't have an initial dark web fetch.  The only time we wouldn't be fetching is with a data partner that hasn't
        // verified their criteria yet.  UKC-2535
        return !(acct.isDataPartner() && !crit.active);
      })
    );
  }
}
