import {UserInterface} from './user.interface';
import {RentReportingInterface} from 'src/app/modules/rent-reporting/models/rent-reporting.interface';
import {ProductInterface} from './product.interface';
import {OfficeInterface} from './office.interface';
import {AccountSettings} from './account-settings.interface';
import {BillingSchemes} from './billing-schemes.interface';
import {DataPartnerService} from 'src/app/shared/services/data-partner.service';
import {Agency} from 'src/app/shared/models/agency';


export interface IAccount {
  user?: UserInterface;
  rent_reporting?: RentReportingInterface;
  product?: ProductInterface;
  office?: OfficeInterface;
  settings?: AccountSettings;
  schemes?: BillingSchemes;
}


/**
 * TODO: Represents an Augmented RPI User in which additional fields are added on the backend.  This is due to our legacy
 * backend's design over time.  We may want to rethink this a bit.  An account object could represent several groups of
 * information, each being their own formal object.
 */
export class Account implements IAccount {

  public user: UserInterface;
  public rent_reporting: RentReportingInterface;
  public product: ProductInterface;
  public office: OfficeInterface;
  public settings: AccountSettings;
  public schemes: BillingSchemes;

  constructor(obj?: Partial<IAccount>) {
    Object.assign(this, obj);
  }

  /**
   * If this account is a prepaid customer
   */
  isDataPartner(): boolean {
    return DataPartnerService.isPartner(this.user.afid);
  }

  /**
   * Prepaid customer that hasn't given us their credit card yet.
   */
  isIncompleteDeferredAuth(): boolean {
    return !!(this.user.deferred_auth && !this.user.deferred_auth_complete);
  }

  /**
   * Assuming we only have one agency at a time, get that Agency value
   */
  getDefaultAgency(): Agency | null {
    if (this.user.agencies.length < 1) {
      return null;
    }

    return Object.values(this.user.agencies)[0].agency_id;
  }
}
