<div class="welcome px-4 py-5">
  <h1 class="text-center">Verify Your Identity</h1>

  <div class="content">
    <p class="fw-bold">To ensure your personal data is protected before we can provide you with access to your credit report and score, we
      need to accurately authenticate your identity. We do this by asking you four questions from information held on your existing
      credit repot that only the genuine individual would know the answers to.</p>
    <p>(For example, 'What was your previous address?' or 'Do you have a joint bank account?')</p>
    <p class="fw-bold">Once these questions have been answered correctly, full access to your credit file will be granted.</p>
    <p>you can choose to answer these questions later if you wish by pressing the SKIP button below. Please note that access to your credit
      report and score will be temporarily restricted, but all other services remain accessible.</p>

    <div class="butts">
      <div>
        <div class="mb-2">
          <button class="btn btn-primary btn-lg rounded-pill" appRecordClickEvent="Complete" appRecordClickPage="Verify ID" (click)="activeModal.close()">
            Complete Verification
          </button>
        </div>
        <div class="fw-light small">Answer questions to access credit report now.</div>
      </div>

      <div>
        <div class="mb-2">
          <button class="btn btn-secondary btn-lg rounded-pill" appRecordClickEvent="Skip" appRecordClickPage="Verify ID" (click)="activeModal.dismiss()">
            Skip <span class="d-none d-sm-inline">Verification</span>
          </button>
        </div>
        <div class="fw-light small">I don't want to access credit report now.</div>
      </div>
    </div>
  </div>
</div>
