import {Component} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css'],
  standalone: true,
  imports: [
    NgIf,
  ],
})
export class ConfirmationModalComponent {

  public hasHeader = true;
  public yesLabel = 'Yes';
  public noLabel = 'No';
  public title: string;
  public message: string;

  constructor(
    public activeModal: NgbActiveModal
  ) {
  }

  yes() {
    this.activeModal.close(true);
  }

  no() {
    this.activeModal.dismiss();
  }
}
