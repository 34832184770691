import {inject, Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse,
} from '@angular/common/http';
import {EMPTY, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {DOCUMENT} from '@angular/common';

/**
 * 419 responses come from Laravel when the CSRF token doesn't match.  This happens when a customer's session is expired.  This will
 * reload the page, fetching a new token in the process as well as putting them on the login page.  This is a rather heavy-handed approach.
 */

@Injectable()
export class Reload419Interceptor implements HttpInterceptor {

  private document = inject(DOCUMENT);

  constructor() {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next
      .handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status == 419) {
            this.document.location.reload();
            // Return a completed observable with no value.  The page should reload soon after with no more errors logged.
            return EMPTY;
          }

          return throwError(() => error);
        }),
      );
  }
}
