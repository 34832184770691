<h3>Dark web Monitoring</h3>

<app-risk-header
  *ngIf="(hasSummaryData$ | async) else emptyData"
  [risks]="(summary$ | async)?.risks"
  [hideZeros]="false"
  [limit6]="true">
</app-risk-header>

<ng-template #emptyData>
  <p class="py-3 pad-resp" *ngIf="(summary$ | async) === null || !(hasSummaryData$ | async)">
    <span *ngIf="shouldBeFetching$ | async">
      We are working on pulling dark web data for you...
    </span>

    <span *ngIf="!(shouldBeFetching$ | async)">
      Dark web reports provide powerful insight into your online security.
      <a class="clickable" [routerLink]="['/dark-web']">Verify your email address</a> to see yours today.
    </span>
  </p>

  <p class="py-3 pad-resp" *ngIf="(summary$ | async) && (hasSummaryData$ | async)">A Dark Web search carried out on
    {{ (summary$ | async)?.lastScanDate | dateTimeFormat }} revealed that none of your personal
    information has been exposed online. </p>
</ng-template>

<a class="clickable" [routerLink]="['/dark-web']" *ngIf="hasSummaryData$ | async">View Now</a>
