import {Component} from '@angular/core';
import {AccountService} from 'src/app/shared/services/account.service';
import {map} from 'rxjs/operators';
import {UserStatus} from 'src/app/shared/models/user-status';
import {Observable} from 'rxjs';
import {AsyncPipe} from '@angular/common';
import {BrokenEnrollService} from 'src/app/modules/iao-chain/broken-enroll/broken-enroll.service';
import {MixpanelService} from 'src/app/shared/mixpanel/mixpanel.service';

@Component({
  selector: 'app-complete-broken-enroll',
  templateUrl: './complete-broken-enroll.component.html',
  styleUrls: ['../../nav.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
  ],
})
export class CompleteBrokenEnrollComponent {
  isBrokenEnroll$: Observable<boolean>;

  constructor(
    accountService: AccountService,
    private brokenEnrollService: BrokenEnrollService,
  ) {
    this.isBrokenEnroll$ = accountService.getAccount().pipe(
      map(acct => acct.user.status == UserStatus.BrokenEnrollment),
    )
  }

  openModalFlow() {
    MixpanelService.track('Click View complete broken enrollment nav item');

    this.brokenEnrollService.start();
  }
}
