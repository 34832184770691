import {Component, Input} from '@angular/core';
import {RatingCommon} from 'src/app/shared/models/report/common/rating-common';


@Component({
  selector: 'app-credit-score-progress-bar',
  templateUrl: './credit-score-progress-bar.component.html',
  styleUrls: ['./credit-score-progress-bar.component.scss'],
  standalone: true,
  imports: [],
})
export class CreditScoreProgressBarComponent {
  get rating(): RatingCommon {
    return this._rating;
  }

  @Input()
  set rating(value: RatingCommon) {
    this._rating = value;

    this.scorePercentage = (value.Score / value.ScoreMax * 100) + '%';
  }

  private _rating: RatingCommon;

  @Input() reportDate: string;

  /**
   * Expects rating as percent value with %
   */
  scorePercentage: string = '0%';

}
