import {Injectable} from '@angular/core';
import {PaginationResult} from 'src/app/shared/models/pagination-result';
import {FlaggedTransactionsInterface} from 'src/app/modules/rent-reporting/models/flagged-transactions.interface';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RentReportingService {
  constructor(
    private http: HttpClient,
    private store: Store,
  ) {
  }

  getFlaggedTransactions(page: number = 1) {
    return this.http.get<PaginationResult<FlaggedTransactionsInterface>>('/api/rent-reporting/flagged-transactions', {params: {page}});
  }

  /**
   * Get the gateway URL from Bud
   */
  getBudGatewayUrl(): Observable<any> {
    const options = {'params': {'baseUrl': window.location.origin}};
    return this.http
      .get('/api/rent-reporting/gateway-url', options)
      .pipe(
        share()
      );
  }

  /**
   * Get the accounts list from Bud
   */
  getBudAccountsList(): Observable<any> {
    return this.http
      .get('/api/rent-reporting/accounts-list')
      .pipe(
        share()
      );
  }

  /**
   * Get the gateway URL from Bud
   */
  saveBudConnection(): Observable<any> {
    return this.http
      .post('/api/rent-reporting/bud-connection', {})
      .pipe(
        share()
      );
  }

  updateRentInfo(updateRentInfoRequest: any): Observable<any> {
    return this.http
      .post('/api/rent-reporting/rent-info', updateRentInfoRequest)
      .pipe(
        share()
      );
  }

  disconnectBanking() {
    return this.http.post('/api/rent-reporting/disconnect', {});
  }
}
