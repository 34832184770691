import {Component} from '@angular/core';
import {MixpanelService} from 'src/app/shared/mixpanel/mixpanel.service';
import {Agency} from 'src/app/shared/models/agency';
import {CreditReportService} from 'src/app/shared/services/credit-report.service';

@Component({
  selector: 'app-view-print-report',
  standalone: true,
  imports: [],
  templateUrl: './view-print-report.component.html',
  styleUrls: ['../../nav.component.scss'],
})
export class ViewPrintReportComponent {

  constructor(
    private creditReportService: CreditReportService,
  ) { }

  viewReport(): void {
    MixpanelService.track('Click View printable report nav item');

    const selectedAgency = this.creditReportService.getSelectedAgencySnapshot();

    // We should put a proper modal which is aware of their current status (MV, broken enroll, thin file) and links accordingly.
    if (selectedAgency === Agency.NONE) {
      alert("You don't appear to have a credit report.  Finish your registration process to continue.");
      return;
    }

    window.open(`/api/report/print?agency=${selectedAgency}`, '_blank');
  }

}
